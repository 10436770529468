.loader_main {
  width: 100%;
  height: 100vh;
  background-color: #8d5be0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*#e7dbfd*/
/*#7f42e*/

.loader {
  position: relative;
  display: inline-block;
}
.loader::before {
  content: "Загрузка";
  color: #e7dbfd;
  /* font-family: "Great Vibes", cursive; */
  font-weight: 400;
  font-style: normal;
  font-size: 9vw;
  letter-spacing: 2px;
  display: inline-block;
  box-sizing: border-box;
  animation: floating 1s ease-out infinite alternate;
}
.loader::after {
  content: "";
  width: 100%;
  height: 10px;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 0;
  top: 100%;
  filter: blur(4px);
  border-radius: 50%;
  box-sizing: border-box;
  animation: animloader 1s ease-out infinite alternate;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}

@keyframes animloader {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}
