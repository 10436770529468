@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  padding: 0;
  background-color: white;
  overflow-x:hidden;  /*для горизонтального*/
  /* font: 1em "Fira Sans", sans-serif; */
}

.none {
  display: none;
}

li {
  list-style-type: none;
}
ul {
  margin-left: 0;
  /* Отступ слева в браузере IE и Opera */
  padding-left: 0;
  /* Отступ слева в браузере Firefox, Safari, Chrome */
}
.App {
  display: block;
  position: relative;
  /* background-color: aquamarine; */
  /* min-height: 200vh; */
  font-family: "Great Vibes", cursive;
  /* font-family: "Noto Serif", serif; */
  font-weight: 400;
  font-style: normal;
}

.navbar {
  position: sticky;
  /* top:-100px; */
  top: 0;
  z-index: 2;
}

/* @media (max-width:840px){
    .navbar{
        top:-150px;
    }
} */

.vercel {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: rgb(14 14 14);
  color: white;
}

.vercel_text {
  font-size: 5vw;
  font-family: "Courier New", Courier, monospace;
}
